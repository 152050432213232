import React from 'react';
import FileUploader from './FileUpload';
import {Amplify, Auth} from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { Authenticator } from '@aws-amplify/ui-react';
import { useState, useEffect } from 'react';

Amplify.configure({
  Auth: {
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_1XqwCNMBx',
    userPoolWebClientId: '2pvvvffgh9c4152il724bcmvj2',
    oauth: {
      domain: 'paperless.auth.ap-northeast-1.amazoncognito.com',
      scope: ['openid', 'profile'],
      redirectSignIn: 'https://www.paperlessassistant.com',
      redirectSignOut: 'https://www.paperlessassistant.com',
      responseType: 'code'
    }
  }
})

function App() {
  const [tenantID, setTenantID] = useState(null);

  useEffect(() => {
    const fetchUserAttributes = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        //console.log(currentUser);
        const userAttributes = currentUser.attributes;
        const tenantID = userAttributes['custom:tenantID'];
        setTenantID(tenantID);
      } catch (error) {
        console.log('error fetching user attributes:', error);
      }
    };

    fetchUserAttributes();
  }, []);

  const signOutHandler = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out:', error);
    }
  }
  return (
    <div className="App">
      {tenantID && <FileUploader tenantID={tenantID} />}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button onClick={signOutHandler}>Sign Out</button>
      </div>
    </div>
  );
}

export default function () {
  return (
    <Authenticator hideSignUp>
      <App />
    </Authenticator>
  )
}
//export default App;
