import React, { Fragment, useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import JSZip from 'jszip';
import md5 from 'md5';
import { Container, Box, TextField, Button, FormControlLabel, Checkbox, TextareaAutosize, makeStyles, InputAdornment, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Paper } from '@material-ui/core';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#f5f5f5',
    borderRadius: '15px',
    padding: '20px'
  },
  button: {
    marginTop: theme.spacing(2),
  },
  textArea: {
    width: '100%',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  textField: {
    marginBottom: theme.spacing(2),
    color: 'black',
    fontSize: '14px',  // Change the font size here
  },
}));

const endpoint = `https://encoo-ocr.my-docs.org`;
// const endpoint = `http://localhost:4000`;

function loadFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = event => {
      resolve({name: file.name, content: event.target.result});
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

const FileUploader = ({ tenantID }) => {
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const [systemOn, setSystemOn] = useState(true);
  const [callTimes, setCallTimes] = useState([]);

  useEffect(() => {
    checkSystemOn();
    getCallTimes();
  }, [])

  async function checkSystemOn() {
    try {
      const { data: { on } } = await axios.get(`${endpoint}/system-status`);
      setSystemOn(on);
    } catch {
      setSystemOn(false);
    }
  }

  async function getCallTimes() {
    try {
      const { data } = await axios.get(`${endpoint}/call-times`);
      setCallTimes(data);
    } catch {
      setSystemOn([]);
    }
  }

  const handleFileChange = async (e) => {
    const files = e.target.files;
    if (files.length <= 0) {
      return;
    }
    let _upload_message = 'アップロードの準備中…';
    setMessage(_upload_message);
    let start = Date.now();
    const zip = new JSZip();
    const fileList = Array.from(files);
    const contents = await Promise.all(fileList.map(loadFile));
    for (const {name, content} of contents) {
      zip.file(name, content);
    }
    const content = await zip.generateAsync({type:"blob"});
    const zipFile = new File([content], `resources.zip`, {type: 'application/zip'});
    if (zipFile.size > 500000000) {
      var overSized = true;
      _upload_message = "500MB 以上のファイル コンテンツをアップロードできません";
    } else {
      let costs = (Date.now() - start) / 1000;
      _upload_message += `${costs.toFixed(2)}秒で完了`;
    }
    start = Date.now();
    setMessage(_upload_message);
    if (overSized) {
      return;
    }
    const formData = new FormData();
    formData.append('file', zipFile);
    let _uploading_message = 'アップロード中…';
    const config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        _uploading_message = `アップロード中…${percentCompleted}%`;
        setMessage([_upload_message, _uploading_message].join('<br>'));
      }
    };
    let response = null;
    try {
      response = await axios.post(`${endpoint}/request-ocr`, formData, config)
      _uploading_message = 'アップロード完了' + `${(Date.now() - start) / 1000}秒で完了`;
      start = Date.now();
      setMessage([_upload_message, _uploading_message].join('<br>'));
    } catch (err) {
      _uploading_message = 'アップロード失敗' + `${(Date.now() - start) / 1000}秒で失敗 ${typeof err?.response?.data === 'string'? err?.response?.data: ''}`;
      setMessage([_upload_message, _uploading_message].join('<br>'));
      return;
    }
    const id = response.data.id;
    let _ocr_message = 'OCRがまもなく開始されます...お待ちください…';
    setTimeout(async function loop() {
      try {
        const response = await axios.get(`${endpoint}/ocr-status?id=${id}`);
        const {status, done} = response.data;
        _ocr_message = status + ` ${(Date.now() - start) / 1000}秒経過`;
        setMessage([_upload_message, _uploading_message, _ocr_message].join('<br>'));
        if (!done) {
          setTimeout(loop, 1000);
        }
      } catch (err) {
        _ocr_message = "OCRエラー";
        setMessage([_upload_message, _uploading_message, _ocr_message].join('<br>'));
      }
    }, 1000);
  }

  if (systemOn) {
    
  }
  return (
    <Container maxWidth="sm" className={classes.container}>
      {
        systemOn? <Fragment>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Typography align="center">===サービス概要と利用方法===</Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" className={classes.textArea}>
                {'■AI-OCRサービス\n\
                人工知能（AI）を活用した光学文字認識（OCR）技術を提供するサービスです。この技術は、スキャンした文書や画像から文字情報を抽出し、デジタルデータとして利用可能にするものです。\n\
                ■AI手書き文字認識サービス\n\
                人工知能（AI）技術を活用して手書きの文字をデジタルデータに変換するサービスです。手書き文字認識は、紙に書かれた文字やタブレットなどのデバイスに手書きされた文字を読み取り、正確にデジタルテキストとして変換します。\n\
                ■自動メール送信機能\n\
                100009@dx-e.jpへ送信します。\n\
                ■制約事項\n\
                回で処理可能な合計サイズ<500MB'.split('\n').map((text, index) => (
                  <React.Fragment key={`${text}-${index}`}>
                    {text}
                    <br />
                  </React.Fragment>
                ))}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Typography align="center">===月内のサービスコールの数===</Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>日付</TableCell>
                    <TableCell>OCR呼び出しの数</TableCell>
                    <TableCell>送信されたメールの数</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {callTimes.map((row) => (
                    <TableRow
                      key={row.date}
                      sx={row.current? { backgroundColor: '#f5f5f5' }: {}}
                    >
                      <TableCell>{row.date}</TableCell>
                      <TableCell>{row.num_of_ocr_calls}</TableCell>
                      <TableCell>{row.num_of_email_calls}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </AccordionDetails>
          </Accordion>
          <Box>
            <input
              style={{ display: 'none' }}
              id="contained-button-file"
              type="file"
              webkitdirectory=""
              directory=""
              accept=""
              onChange={handleFileChange}
              multiple={true}
            />
            <label htmlFor="contained-button-file">
              <Box width="100%">
                <Button 
                  variant="contained" 
                  color="primary" 
                  component="span"
                  fullWidth
                >
                  フォルダ選択
                </Button>
              </Box>
            </label>
          </Box>
          <Box display="flex" alignItems="center" marginTop={1}>
            <Typography className={classes.textArea} >
              <div dangerouslySetInnerHTML={{__html: message}}></div>
            </Typography>
          </Box>
        </Fragment>: <Fragment>
          <Box display="flex" alignItems="center" marginTop={1}>
            <Typography className={classes.textArea} >
            システムはメンテナンス中です。問題はすぐに修正されますので、しばらくお待ちください。
            </Typography>
          </Box>
        </Fragment>
      }
    </Container>
  );
}
export default FileUploader;