import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_1XqwCNMBx',
    userPoolWebClientId: '2pvvvffgh9c4152il724bcmvj2',
    oauth: {
      domain: 'paperless.auth.ap-northeast-1.amazoncognito.com',
      scope: ['openid', 'profile'],
      redirectSignIn: 'https://www.paperlessassistant.com',
      redirectSignOut: 'https://www.paperlessassistant.com',
      responseType: 'code'
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
